// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-black-chef-africa-foundation-js": () => import("./../../../src/pages/black-chef-africa-foundation.js" /* webpackChunkName: "component---src-pages-black-chef-africa-foundation-js" */),
  "component---src-pages-culinary-over-suicide-2021-js": () => import("./../../../src/pages/culinary-over-suicide-2021.js" /* webpackChunkName: "component---src-pages-culinary-over-suicide-2021-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-teen-chef-bootcamp-js": () => import("./../../../src/pages/teen-chef-bootcamp.js" /* webpackChunkName: "component---src-pages-teen-chef-bootcamp-js" */),
  "component---src-templates-course-details-js": () => import("./../../../src/templates/course-details.js" /* webpackChunkName: "component---src-templates-course-details-js" */)
}

